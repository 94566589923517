<style lang="scss" global>:global(*) {
  box-sizing: border-box;
  scroll-behavior: smooth; }

:global(html),
:global(body),
:global(div),
:global(span),
:global(applet),
:global(object),
:global(iframe),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(p),
:global(blockquote),
:global(pre),
:global(a),
:global(abbr),
:global(acronym),
:global(address),
:global(big),
:global(cite),
:global(code),
:global(del),
:global(dfn),
:global(em),
:global(img),
:global(ins),
:global(kbd),
:global(q),
:global(s),
:global(samp),
:global(small),
:global(strike),
:global(strong),
:global(sub),
:global(sup),
:global(tt),
:global(var),
:global(b),
:global(u),
:global(i),
:global(center),
:global(dl),
:global(dt),
:global(dd),
:global(ol),
:global(ul),
:global(li),
:global(fieldset),
:global(form),
:global(label),
:global(legend),
:global(table),
:global(caption),
:global(tbody),
:global(tfoot),
:global(thead),
:global(tr),
:global(th),
:global(td),
:global(article),
:global(aside),
:global(canvas),
:global(details),
:global(embed),
:global(figure),
:global(figcaption),
:global(footer),
:global(header),
:global(hgroup),
:global(menu),
:global(nav),
:global(output),
:global(ruby),
:global(section),
:global(summary),
:global(time),
:global(mark),
:global(audio),
:global(video) {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

:global(article),
:global(aside),
:global(details),
:global(figcaption),
:global(figure),
:global(footer),
:global(header),
:global(hgroup),
:global(main),
:global(menu),
:global(nav),
:global(section) {
  display: block; }

:global(a) :global(img) {
  border: none; }

:global(blockquote) {
  quotes: none; }

:global(blockquote:before),
:global(blockquote:after) {
  content: "";
  content: none; }

:global(table) {
  border-collapse: collapse;
  border-spacing: 0; }

:global(caption),
:global(th),
:global(td) {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/
:global(html.wf-loading) :global(*) {
  opacity: 0; }

:global(h1), :global(h2), :global(h3) {
  font-weight: 400;
  font-family: 'Montserrat Alternates', sans-serif; }

:global(h1) {
  font-size: 3.3rem; }

:global(h2) {
  font-size: 2.1rem; }

:global(h3) {
  font-size: 1.3rem; }

:global(p) {
  font-weight: 200;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem; }

:global(a) {
  font-family: 'Montserrat', sans-serif;
  font-size: 0.8rem;
  text-decoration: none; }

:global(li) {
  font-family: 'Montserrat', sans-serif; }

:global(a),
:global(a:link),
:global(a:hover),
:global(a:visited),
:global(a:active) {
  text-decoration: none; }

:global(.lead) {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .1rem;
  color: #333333;
  font-size: 0.7rem; }

:global(#intro__lead) {
  font-size: 0.7rem; }

:global(#callout__lead) {
  font-size: 0.7rem;
  width: 100%; }

:global(canvas) {
  background-color: #221f26; }

:global(section) {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 4em; }
  @media (max-width: 37.5em) {
    :global(section) {
      min-height: 80vh;
      justify-content: flex-start;
      padding-top: 2em; } }

:global(article) {
  width: 100vw;
  padding: 5em 0; }
  @media (max-width: 37.5em) {
    :global(article) {
      padding: 2em 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1em 0; } }
  :global(article) :global(.section-header) {
    padding-left: 22em;
    max-width: 75vw; }
    @media (max-width: 75em) {
      :global(article) :global(.section-header) {
        padding-left: 9em;
        max-width: 100%; } }
    @media (max-width: 37.5em) {
      :global(article) :global(.section-header) {
        padding: 0;
        max-width: 100%; } }
    :global(article) :global(.section-header) :global(.lead) {
      margin-bottom: 8px;
      margin-left: 2px; }
    :global(article) :global(.section-header) :global(.title), :global(article) :global(.section-header) :global(.subtitle) {
      max-width: 60vw; }
      @media (max-width: 37.5em) {
        :global(article) :global(.section-header) :global(.title), :global(article) :global(.section-header) :global(.subtitle) {
          max-width: 80vw; } }
    :global(article) :global(.section-header) :global(.title) {
      margin-bottom: 16px;
      color: #00B8D1;
      font-size: 2.1rem; }
      @media (max-width: 37.5em) {
        :global(article) :global(.section-header) :global(.title) {
          font-size: 1.3rem;
          min-width: 80vw; } }
    :global(article) :global(.section-header) :global(.subtitle) {
      color: #1D1E68;
      font-size: 1.3rem;
      line-height: 1.5; }
      @media (max-width: 37.5em) {
        :global(article) :global(.section-header) :global(.subtitle) {
          font-size: 1rem; } }
  :global(article) :global(.copy) {
    display: flex;
    align-items: center; }
    :global(article) :global(.copy) :global(p) {
      padding: 5em 20em;
      font-size: 1.1rem;
      color: #333333;
      line-height: 1.7; }
      @media (max-width: 75em) {
        :global(article) :global(.copy) :global(p) {
          padding: 3em 8em; } }
      @media (max-width: 37.5em) {
        :global(article) :global(.copy) :global(p) {
          padding: 3em 0;
          line-height: 1.5;
          font-size: 0.8rem;
          max-width: 80vw;
          margin-bottom: 2em; } }

:global(.callout) {
  position: relative;
  max-height: 80vh;
  color: #1D1E68;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  margin: 0 2em;
  padding: 2em; }
  @media (max-width: 37.5em) {
    :global(.callout) {
      padding: 1em 0;
      flex-direction: column;
      overflow: hidden;
      max-width: 90vw;
      max-height: 100%; } }
  :global(.callout) :global(.bk) {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url("../assets/images/BIA_Pattern_Fog.jpg");
    z-index: -1; }
  :global(.callout) :global(.closing-bk) {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1; }
  :global(.callout__container) {
    display: flex;
    width: 50%; }
  :global(.callout__image) {
    margin-left: -2em;
    overflow: hidden; }
    :global(.callout__image) :global(img) {
      transform: translate(-50px, 50px); }
  :global(.callout__copy) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 4em; }
    @media (max-width: 37.5em) {
      :global(.callout__copy) {
        align-items: center;
        padding: 0; } }
    :global(.callout__copy) :global(p) {
      max-width: 80%;
      font-weight: 600;
      line-height: 1.7;
      font-size: 1rem; }
      @media (max-width: 37.5em) {
        :global(.callout__copy) :global(p) {
          font-size: 0.8rem; } }
  :global(.callout) :global(.closing-copy) {
    padding: 0 6em;
    margin: 4em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2em; }
    @media (max-width: 37.5em) {
      :global(.callout) :global(.closing-copy) {
        align-items: flex-start;
        padding: 0 0 0 2em;
        margin: 1em 0; } }
    :global(.callout) :global(.closing-copy) :global(p) {
      max-width: 80%;
      font-weight: 600;
      line-height: 1.7;
      font-size: 1.3rem;
      color: #fff; }
      @media (max-width: 37.5em) {
        :global(.callout) :global(.closing-copy) :global(p) {
          font-size: 1rem; } }

/* Call to Action Button */
:global(.cta-btn) {
  font-size: 0.8rem;
  font-weight: 700;
  display: inline-block;
  position: relative;
  padding: 1rem 2rem;
  line-height: 1;
  z-index: 1;
  background-color: #FAAB18;
  color: #fff;
  border: none;
  max-height: 56px;
  text-transform: uppercase;
  letter-spacing: .1em; }
  @media (max-width: 37.5em) {
    :global(.cta-btn) {
      font-size: 0.6em; } }

:global(.cta-btn-alt) {
  font-size: 0.8rem;
  font-weight: 700;
  display: inline-block;
  position: relative;
  padding: 1rem 2rem;
  line-height: 1;
  z-index: 1;
  background-color: #E26D5A;
  color: #fff;
  border: none;
  max-height: 56px;
  text-transform: uppercase;
  letter-spacing: .1em; }
  @media (max-width: 37.5em) {
    :global(.cta-btn-alt) {
      font-size: 0.6em; } }

:global(.chart-section) {
  background-color: #ebebeb;
  color: #1D1E68;
  padding: 5em 3em;
  overflow: hidden; }
  @media (max-width: 37.5em) {
    :global(.chart-section) {
      padding: 4em 2em 2em 2em; } }
  :global(.chart-section) :global(.chart__copy) {
    max-width: 800px; }
    :global(.chart-section) :global(.chart__copy__title) {
      margin-bottom: 8px; }
      @media (max-width: 37.5em) {
        :global(.chart-section) :global(.chart__copy__title) {
          font-size: 1.3rem; } }
    :global(.chart-section) :global(.chart__copy__description) {
      margin-bottom: 32px; }
  :global(.chart-section) :global(.input-container) {
    margin-right: 1em; }
    :global(.chart-section) :global(.input-container) :global(label) {
      color: #fff;
      background-color: #1D1E68;
      padding: 1em 2em;
      border-radius: 10px;
      font-family: 'Montserrat', sans-serif; }
      :global(.chart-section) :global(.input-container) :global(label) :global(input) {
        margin-right: 6px; }
  :global(.chart-section) :global(.chart-container) {
    height: 400px;
    width: 800px;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif; }
    @media (max-width: 37.5em) {
      :global(.chart-section) :global(.chart-container) {
        height: 200px;
        width: 320px; } }
    :global(.chart-section) :global(.chart-container) :global(svg) {
      max-width: 100%; }
      :global(.chart-section) :global(.chart-container) :global(svg) :global(text) {
        font-weight: bold; }
    :global(.chart-section) :global(.chart-container) :global(.tick) :global(text) {
      fill: #1D1E68; }
  :global(.chart-section) :global(.bar-chart) :global(.x-axis) :global(.tick:last-of-type) :global(text) {
    transform: translateX(12px); }
  @media (max-width: 37.5em) {
    :global(.chart-section) :global(.bar-chart) :global(.x-axis) :global(.tick:nth-child(2n)) {
      display: none; } }
  @media (max-width: 75em) {
    :global(.chart-section) :global(.bar-chart) :global(.y-axis) :global(.tick:not(:nth-last-child(-n+3))) {
      display: none; } }
  @media (max-width: 75em) {
    :global(.chart-section) :global(.bar-chart) :global(.y-axis) {
      transform: translate(10px, 0);
      font-size: 0.8rem; }
      :global(.chart-section) :global(.bar-chart) :global(.y-axis) :global(text) {
        fill: #fff; } }</style>