<script>
    import MediaQuery from '../tools/MediaQuery.svelte';
    import Icon from '../tools/Icon.svelte';
    import { fly } from 'svelte/transition'
    import { onMount } from 'svelte'

    let visible = false;

    function showMenu() {
        visible = true;
    }
    function hideMenu() {
        visible = false;
    }
   
</script>

<style lang="scss">/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/
.header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 64px;
  padding: 0 2em 0 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1D1E68;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px); }
  .header h3 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    min-width: 100px;
    pointer-events: none; }
    .header h3 em {
      color: #00B8D1; }
  .header .mobile-title {
    font-size: 1rem; }
  .header img {
    min-width: 100px;
    max-height: 32px; }
  .header ul {
    list-style-type: none;
    display: flex; }
    .header ul li {
      padding: 0 1em 0 1em; }
      .header ul li a {
        font-size: 1rem;
        text-decoration: none;
        color: #1D1E68; }

.mobile {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(29, 29, 29, 0.9);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  font-family: 'Montserrat Alternates', sans-serif; }
  .mobile ul {
    list-style-type: none; }
    .mobile ul #back {
      font-size: 1.3rem;
      color: #FAAB18; }
    .mobile ul li {
      margin: 2em 0; }
      .mobile ul li a {
        font-size: 2.1rem;
        text-decoration: none;
        color: #E26D5A; }</style>



<MediaQuery query="(min-width: 1440px)" let:matches>
	{#if matches}
        <div class="header">
            <a href="https://blackinnovationalliance.com/">
                <img src="./assets/images/BIA_Logo.png" alt="bia-logo">
            </a>
            <h3>Black Innovation Cens<em>Us</em></h3>
            <nav>
                <ul>
                    <li><a href="#place">Place</a></li>
                    <li><a href="#service">Service</a></li>
                    <li><a href="#innovators">Innovators</a></li>
                    <li><a href="#orgs">Organizations</a></li>
                </ul>
            </nav>
        </div>
	{/if}
</MediaQuery>

<MediaQuery query="(max-width: 1440px)" let:matches>
	{#if matches}
        <div class="header">
            <div on:click={showMenu}><Icon name="menu" width="1.5em" height="1.5em"/></div>
            <h3 class="mobile-title">Black Innovation Cens<em>Us</em></h3>
        </div>
        {#if visible}
            <div class="mobile" transition:fly="{{ y: 400, duration: 1000 }}">
                <nav>
                    <ul>
                        <li id="back" on:click={hideMenu}>Back</li>
                        <li on:click={hideMenu}><a href="#place">Place</a></li>
                        <li on:click={hideMenu}><a href="#service">Service</a></li>
                        <li on:click={hideMenu}><a href="#innovators">Audience</a></li>
                        <li on:click={hideMenu}><a href="#orgs">Organizations</a></li>
                    </ul>
                </nav>
            </div>
        {/if}
	{/if}
</MediaQuery>