<script>
	import { geoAlbersUsa, geoPath } from "d3-geo";
	import { scaleQuantize } from "d3-scale";
	import { extent, rollup } from "d3-array";
	import { onMount } from "svelte";
	import { feature } from "topojson";
	import ChartTitle from '../tools/ChartTitle.svelte'

	export let data;
	export let title; 
	export let description;

	let newList = data
	let mapData = []
	let current = "hide";
	const colors = ["#adf5ff","#70eeff","#47eaff","#33e7ff","#00b8d1","#00a2b8","#0090a3","#007e8f"]
	let colorScale = () => {};
	let width = 1200
	let height = width * 0.7
	let selectedState = ""
	let scaleNumber = 800;
	
	newList.sort(function(a,b) {
		let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
		if (nameA < nameB) 
			return -1 
		if (nameA > nameB)
			return 1
		return 0 
	})
	let summary = rollup(data, v => v.length, d => d.state)
	let orgExtent = extent(summary, d => d[1])
	
	$: colorScale = scaleQuantize()
		.domain(orgExtent)
		.range(colors);

	$: getColor = function (feature) {
		let number = summary.get(feature.properties.name)
		let color = colorScale(number)
		if (color) {
			return color
		} else {
			return "#fff"
		}
	}

	const filteronState = function (state) {
		newList = data.filter(d => d.state == state)
		return newList
	}

	if( window.screen.width <= 480) {
		scaleNumber = 350
	} else if( window.screen.width <= 1280) {
		scaleNumber = 600
	}

	const projectionAlbersUsa = geoAlbersUsa().scale(scaleNumber)
  
	let currentProj = projectionAlbersUsa;
	let path = geoPath().projection(currentProj);

  
	onMount(async function() {
	  const response = await fetch(
		"https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json"
	  );
	  const json = await response.json();
	  const topoData = feature(json, json.objects.states);
	  const land = {
		...topoData,
	  };
	  mapData = land.features;
	});
</script>
  
<style lang="scss">/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/
#interface {
  width: 100%;
  display: flex; }
  @media (max-width: 37.5em) {
    #interface {
      flex-direction: column; } }

.conclusion__cta {
  margin-bottom: 3em;
  width: 100%;
  max-width: 800px; }
  @media (max-width: 37.5em) {
    .conclusion__cta {
      display: flex;
      justify-content: flex-start; } }

#map-wrapper {
  width: 50%;
  height: 500px; }
  @media (max-width: 37.5em) {
    #map-wrapper {
      width: 100%;
      height: 250px; } }

.stateShape {
  stroke: #708090;
  stroke-width: 0.15;
  transition: all .5s ease-in-out; }

.stateShape:hover {
  stroke: #E26D5A;
  stroke-width: 1.5px; }

.not-filled {
  opacity: .2;
  pointer-events: none; }

.states {
  transform: translate(-140px, 0); }
  @media (max-width: 75em) {
    .states {
      transform: translate(-200px, 0); } }
  @media (max-width: 37.5em) {
    .states {
      transform: translate(-320px, -150px); } }

.org-list {
  max-width: 50%;
  height: 600px;
  overflow-y: auto;
  padding: 1em;
  border: 2px solid;
  border-radius: 10px; }
  @media (max-width: 37.5em) {
    .org-list {
      max-width: 100%; } }
  .org-list__item {
    background-color: #1D1E68;
    padding: 1.5em 3em;
    margin: .5em 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    color: #00B8D1;
    border-radius: 10px; }
    @media (max-width: 37.5em) {
      .org-list__item {
        flex-direction: column; } }
    .org-list__item__title-line {
      display: flex;
      align-items: center;
      margin-bottom: 8px; }
    .org-list__item #card-lead {
      color: #ECE5F0; }
    .org-list__item__programs {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 16px; }
      .org-list__item__programs__program {
        background-color: #ECE5F0;
        color: #1D1E68;
        padding: 0.5em 0.6rem;
        border-radius: 20px;
        font-size: 0.6rem; }
    .org-list__item h3 {
      color: #fff;
      margin-right: 1em; }
      @media (max-width: 37.5em) {
        .org-list__item h3 {
          margin: 0; } }
    .org-list__item a {
      text-decoration: none;
      color: #fff; }
    .org-list__item .right {
      margin-top: 8px;
      max-width: 500px;
      max-height: 100px; }
      @media (max-width: 37.5em) {
        .org-list__item .right {
          margin-left: 0; } }
    .org-list__item .hide {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden; }
    .org-list__item .show {
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 500px;
      display: block; }</style>
  
<section id="orgs" class="chart-section">
	<ChartTitle
		number={0} 
		title={title}
		description= {description}
	/>
	<div class="conclusion__cta">
		<a href="https://airtable.com/shrtgsfpPWmwcVVX1" class="cta-btn-alt">Submit an Org</a>
	</div>
	<div id="interface">
		<div class="chart-container" id="map-wrapper" bind:clientWidth="{width}">
			<svg style="{`width: ${width}px; height: ${height}px`}">
				<g class="states">
					{#each mapData as feature}
						<path
						d={path(feature)}
						id={feature.properties.name}
						class="stateShape {getColor(feature) != "#EA96CA" ? "filled" : "not-filled"}"
						fill={getColor(feature)} 
						on:click={(event) => { 
							selectedState = event.target.id
							filteronState(event.target.id)
						}}
						/>
					{/each}
				</g>
			</svg>
		</div>
		<div class="org-list">
			<h2 class="selected-state">{selectedState}</h2>
			{#each newList as org}
				<div class="org-list__item" >
					<div class="left">
						<div class="org-list__item__title-line">
							<a href="{org.site}">  
								<h3>{org.name}</h3>
							</a>
						</div>
						<p id="card-lead" class="lead">{org.type}</p>
					</div>
					<div class="right" class:show="{current === 'show'}" class:hide="{current === 'hide'}" >
						<p class="org-description" >{org.description}</p>
					</div>
					{#if org.programs !== undefined}
						<div class="org-list__item__programs">
							{#each org.programs as program}
							<p class="org-list__item__programs__program">{program}</p>
							{/each}
						</div>
					{:else}
						<p>No Programs Avaliable</p>
					{/if}
				</div>
		  	{/each}
		</div>
	</div>
</section>