<script>
    import Copy from '../../data/doc.json'
    import Image from '../tools/Image.svelte'
    import MapInterface from '../charts/MapInterface.svelte'
    import gsap from 'gsap'
    import { onMount } from 'svelte'
    
    export let data;

    let y;

    // onMount(() => {
    //     let textWrapper = document.querySelector('.intro__area__title');
    //     textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    //     let tl = gsap.timeline({delay: .25})
    //     tl.fromTo(".letter", 
    //     { 
    //         opacity: 0
    //     },
    //     { 
    //         opacity: 1,
    //         stagger: { 
    //                 amount: .5, 
    //                 grid: 'auto',
    //                 ease: 'power3.out', 
    //             }
    //     }
    //     )
    // })
</script>

<style lang="scss">/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/
.conclusion {
  padding-top: 0;
  margin-top: -5em; }

#closing {
  flex-direction: column;
  justify-content: center;
  margin: 0; }
  @media (max-width: 37.5em) {
    #closing {
      padding: 2em 0;
      max-width: 100%; } }
  #closing .closing-copy > p {
    min-width: 90%; }
  #closing .ctas {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px; }
    @media (max-width: 56.25em) {
      #closing .ctas {
        width: 100%;
        margin-top: 8px;
        flex-direction: column;
        gap: 8px;
        max-width: 95%; } }
    @media (max-width: 37.5em) {
      #closing .ctas {
        max-width: 85%; } }</style>


<section class="conclusion">
    <div id="closing" class="callout">
        <div class="closing-bk">
            <Image width={110} height={180} format="%" src="/assets/images/BIA_Pattern_Black.jpg" alt="Black Pattern"/>
        </div>
        <div class="callout__contianer closing-copy ">
            <Image width={200} src="./assets/images/BIA_Logo_White.png" alt=""/>
            <p>{Copy.conclusion}</p>
            <div class="ctas">
                <a class="cta-btn" href="https://blackinnovationalliance.com/">Learn About BIA</a>
                <a class="cta-btn-alt" href="https://docsend.com/view/mr89aqjyn7uyjn4a">Read the Executive Summary</a>
                <a class="cta-btn" href="https://airtable.com/shrL6t3iLBN8mm0L7">Dive into the Data</a>
            </div>
        </div>
    </div>

    <MapInterface 
        data={data}
        title={Copy.conChartTitle}
        description={Copy.conChartSub}
    />
</section>
