<script>
    import { rollup } from 'd3-array'
    import Image from '../tools/Image.svelte'

    export let data;
    export let category;
    export let dataPoint;
    export let info;
    export let mark = "";
    export let manual = false;

    const percentageOfCategory = () => {
        if(data) {
            let sum = 0;
            let name;
    
            let summary = rollup(data, v => v.length, d => d[category])
            let flatData = Array.from(summary, ([variable, value]) => ({ variable, value }))
            for(let i = 0; i < flatData.length; i++){
                sum += flatData[i].value;
            }
            let percent = (summary.get(dataPoint) / sum) * 100
            return Math.round(percent)
        } else {
            return false
        }
    }



</script>

<style lang="scss">/*

0 - 600px:          Phone
600px - 900px       Table Portrait
900px - 1200px      Table Landscape
[1200px - 1800px]   Desktop Normal Styles
1800px +            Big Desktop

1em = 16px

ORDER: Base + Typography > Generar Layout + Grid > Page Layout > Components

*/
.stat-container {
  position: relative;
  width: 100%;
  min-height: 120px;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 6em;
  overflow: hidden; }
  @media (max-width: 75em) {
    .stat-container {
      padding: 3em; } }
  @media (max-width: 37.5em) {
    .stat-container {
      padding: 3em;
      flex-direction: column;
      height: 100%; } }
  .stat-container .stat-background {
    position: absolute;
    left: 0; }
    @media (max-width: 37.5em) {
      .stat-container .stat-background {
        top: 0;
        height: 100%;
        width: 100%; } }
  .stat-container .gradient {
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #00d9ff 0%, #1d1e67 100%);
    width: 100%;
    height: 100%;
    opacity: .75; }
  .stat-container .stat, .stat-container .info {
    z-index: 2; }
  .stat-container .stat {
    font-size: 7rem;
    padding-right: 1em; }
    @media (max-width: 37.5em) {
      .stat-container .stat {
        padding-right: 0;
        padding-bottom: 16px;
        align-self: flex-start; } }
  @media (max-width: 37.5em) {
    .stat-container .info {
      font-size: 1.3rem; } }</style>

<div class="stat-container">
    <div class="stat-background">
        <Image width={100} height={100} format="%" src="/assets/images/BIA_Pattern_Horizon.jpg" alt="Black Woman"/>
    </div>
    {#if manual}
        <h2 class="info">{info}</h2>
    {:else}
        <h1 class="stat">{percentageOfCategory() ? mark : ""}{percentageOfCategory() ? percentageOfCategory() + "%" : ""}</h1>
        <h2 class="info">{info}</h2>
    {/if}
    <div class="gradient"></div>
</div>